$ = jQuery;


//-----------------------------------------------
//Modal function by Lewys
//-----------------------------------------------
CustomModal = {
	'modal': '',
	'ajax': true,
	'ajax_extra': {},
	'content': false,
	'callback': false,
	create: function( background_colour, ajax, ajax_extra, content, callback ){

		var sm = this;

		if(typeof ajax !== 'undefined') {
			sm.ajax = ajax;
		}
		if(typeof ajax_extra !== 'undefined') {
			sm.ajax_extra = ajax_extra;
		}
		if(typeof content !== 'undefined') {
			sm.content = content;
		}
		if(typeof callback !== 'undefined') {
			sm.callback = callback;
		}

		sm.modal = $('<div><div class="custom-modal-inner"><div class="close-modal"></div><div class="inner"></div></div></div>').attr('class', 'custom-modal loading').height($(document).height());
		sm.modal.find('> .custom-modal-inner').css({
			'background-color': background_colour
		});
		$('body').append(sm.modal);

		if ( sm.content ) {
			sm.fill( sm.content );
		} else {
			sm.do_ajax();
		}

		sm.show();

		$(window).resize(function() {
			sm.remove();
		});

		sm.modal.click(function(e) {
			if($(e.target).hasClass('custom-modal')){
				sm.remove();
			}
		});

		sm.modal.find('.close-modal').click(function(){
			sm.remove();
		});
	},
	do_ajax: function(){
		var sm = this;
		var data = {
			'action': sm.ajax
		};

		if(this.ajax_extra){
			$.extend(data, this.ajax_extra);
		}

		$.post(ajaxurl, data, function(response){

			if(response){
				sm.fill(response);

				setTimeout(function(){
					var inner = sm.modal.find('> .custom-modal-inner');
					var adjusted_top_value = ( (inner.innerHeight() + 20) >= $(window).height() ? 20 : (($(window).height() - inner.innerHeight()) / 2) );
					var margin_top_offset = adjusted_top_value - parseInt(inner.css('top'), 10);

					if ( margin_top_offset < 0 ) {
						inner.css('margin-top', margin_top_offset);
					}
				}, 100);
			}else{
				sm.remove();
			}

		}).fail(function() {
			sm.remove();
		});
	},
	fill: function(content){
		var inner = this.modal.find('> .custom-modal-inner');
		inner.find('> .inner').html(content);
	},
	show: function(){
		this.resize();
		this.modal.addClass('shown');
		$('body').addClass('modal-open');
		var inner = this.modal.find('> .custom-modal-inner');
		// inner.addClass('shown');

		if(this.callback !== false){
			var current = this;
			setTimeout(function(){
				current.callback();
				current.modal.removeClass('loading');
			}, 1000);

			this.resize();
		} else {
			this.modal.removeClass('loading');
		}
	},
	resize: function(){
		var inner = this.modal.find('> .custom-modal-inner');

		var top_value = ( (inner.innerHeight() + 20) >= $(window).height() ? 20 : (($(window).height() - inner.innerHeight()) / 2) );

		inner.css({
			'top': top_value,
			'left': ( (inner.innerWidth() + 20) >= $(window).width() ? 20 : (($(window).width() - inner.innerWidth()) / 2) ),
			'max-height': $(window).height() - 40,
			'max-width': $(window).width() - 40
		});

		inner.find('> .inner').css({
			'max-height': $(window).height() - 40 - parseInt(inner.css('padding-top'), 10) - parseInt(inner.css('padding-bottom'), 10)
		});

	},
	remove: function(){
		this.modal.remove();
		$('body').removeClass('modal-open');
	}
};


//-----------------------------------------------
//Function to set Nav Button Positions Reponsive
//-----------------------------------------------
function setNavButtons() {
	var winWidth = $(window).width();
	var buttonWidth = $('#home-slider .owl-prev').width();
	var buttonWidth2 = $('#twitter-slider .owl-prev').width();
	var containerWidth = $('.container').width();
	var remainedWidth = ( winWidth - containerWidth ) / 2;
	var tweetsContainerHeight = $('.twitter-section .container').height();
	var tweetsSliderHeight = $('#twitter-slider').height();
	
	
	$('#twitter-slider .owl-prev').css('top',( - (tweetsContainerHeight - tweetsSliderHeight) / 2 )); 	
	$('#twitter-slider .owl-next').css('top',( - (tweetsContainerHeight - tweetsSliderHeight) / 2 )); 	

	if ( (remainedWidth/2) > buttonWidth ) {
		$('#home-slider .owl-controls').css('z-index','-1');
		$('#home-slider .owl-prev').css('left', - (remainedWidth/2)-(buttonWidth/2) );
		$('#home-slider .owl-next').css('right', - (remainedWidth/2)-(buttonWidth/2) );
	} else {
		$('#home-slider .owl-controls').css('z-index','5000');
		$('#home-slider .owl-prev').css('left', buttonWidth);
		$('#home-slider .owl-next').css('right', buttonWidth);
	}
	
	
	if ( (remainedWidth/2) > buttonWidth2 ) {
		$('#twitter-slider .owl-controls').css('z-index','-1');
		$('#twitter-slider .owl-prev').css('left', - (remainedWidth/2)-(buttonWidth2/2) );
		$('#twitter-slider .owl-next').css('right', - (remainedWidth/2)-(buttonWidth2/2) );
	} else {
		$('#twitter-slider .owl-controls').css('z-index','5000');
		$('#twitter-slider .owl-prev').css('left', buttonWidth2);
		$('#twitter-slider .owl-next').css('right', buttonWidth2);
	}
}



$(document).ready(function(){
	
	
	//-------------------------------------------
	//Fill sidebar menu with arrows
	//-------------------------------------------
	$('.sidebar-menu>li>.sub-menu>li.menu-item-has-children').each(function(){
		$(this).find('.sub-menu').before('<span class="icon-icons_down-arrow-white"></span>');
	});
	$('.responsive-sub-menu>li>.sub-menu>li.menu-item-has-children').each(function(){
		$(this).find('.sub-menu').before('<span class="icon-icons_down-arrow-white"></span>');
	});
	
	
	//-------------------------------------------
	//Set sidebar sub-menus in the DOM
	//-------------------------------------------
	$('.sidebar-menu>li>.sub-menu>li .sub-menu').each(function(){
		$(this).insertAfter($(this).parent('li'));
	});
	$('.responsive-sub-menu>li>.sub-menu>li .sub-menu').each(function(){
		$(this).insertAfter($(this).parent('li'));
	});
	
	//-------------------------------------------
	//Handle Sidebar Nav toggle
	//-------------------------------------------
	$('.sidebar-menu>li>.sub-menu>li.menu-item-has-children span').click(function(){
		$(this).toggleClass('opened');
		$(this).parent('li').next().slideToggle();
	});
	$('.responsive-sub-menu>li>.sub-menu>li.menu-item-has-children span').click(function(){
		$(this).toggleClass('opened');
		$(this).parent('li').next().slideToggle();
	});
	
	$('.responsive-sub-menu-toggle').click(function(){
		$(this).toggleClass('opened');
		$('.responsive-sub-menu-toggle .toggle').toggleClass('opened');
		$('.responsive-sub-menu').toggleClass('opened');
		$('.responsive-sub-menu').slideToggle();
	});
	
	
	
	//-------------------------------------------
	//Auto toggle current 
	//-------------------------------------------
	$('.sidebar-menu>li>.sub-menu .current-menu-item').each(function(){
		$(this).parent('.sub-menu').show();
		$(this).parent('.sub-menu').prev().children('span').addClass('opened');
	});
	$('.responsive-sub-menu>li>.sub-menu .current-menu-item').each(function(){
		$(this).parent('.sub-menu').show();
		$(this).parent('.sub-menu').prev().children('span').addClass('opened');
	});
	
	
	
	//-------------------------------------------
	//Add some helper classes to paginations
	//-------------------------------------------
	$('.post-navigation ul.page-numbers li a.prev').parent('li').addClass('prev-page');
	$('.post-navigation ul.page-numbers li a.next').parent('li').addClass('next-page');
	$('.post-navigation ul.page-numbers li a.prev').addClass('nav-button');
	$('.post-navigation ul.page-numbers li a.next').addClass('nav-button');
	if ($('.post-navigation ul.page-numbers li.prev-page').length === 0) {
		var prev_label = $('#prev_text').val();
		$('.post-navigation ul.page-numbers').prepend('<li class="prev-page"><span>'+prev_label+'</span></li>');
	}
	if ($('.post-navigation ul.page-numbers li.next-page').length === 0) {
		var next_label = $('#next_text').val();
		$('.post-navigation ul.page-numbers').append('<li class="next-page"><span>'+next_label+'</span></li>');
	}
	
	
	//-------------------------------------------
	//MatchHeight these elements
	//-------------------------------------------
	var match_selectors = [
		'#twitter-slider .slider-item .inner',
		'.home-content-pods-section .pod-box',
		'.latest-news-home-section .latest-article-box .title',
		'.latest-news-home-section .latest-article-box .date',
		'.latest-news-home-section .latest-article-box .intro',
		'.latest-news-home-section .latest-article-box',
		'.section-block.quick-links .quick-box',
		'.related-articles .related-article-box .title',
		'.wc-events .event-box .category',
		'.wc-events .event-box .title',
		'.wc-events .event-box .date',
		'.wc-events .event-box .location',
		'.staff-list .staff-box'
	];
	
	for (var i=0; i < match_selectors.length; i++) {
		$(match_selectors[i]).matchHeight();
	}
	
	
	
	
	
	//-------------------------------------------
	//Handle Footer Back to top button 
	//-------------------------------------------
	$('#back-to-top-btn').click(function(){
		$('.body-wrapper').animate({
			scrollTop:0
		},400);
	});
	
	
	
	
	//-------------------------------------------
	//Handle Video Play button 
	//-------------------------------------------
	$('.video-play-button').click(function(){
		video_type = $(this).attr('data-video-type');
		video_id = $(this).attr('data-video-id');
		
		if (video_type == 'youtube') {
			src = 'https://www.youtube.com/embed/'+video_id+'?rel=0&showinfo=0&autoplay=1';
		} else {
			src = 'https://player.vimeo.com/video/'+video_id+'?autoplay=1';
		}

		content = '<iframe frameborder="0" height="100%" width="100%" src="'+src+'"></iframe>';
		
		CustomModal.create('#FFFFFF', false, {}, content,function(){
			innerheight = $('.custom-modal>.custom-modal-inner').innerHeight();	
			$('.custom-modal>.custom-modal-inner>.inner').css('height',innerheight);
		}); 
	});
	
	
	
	
	
	
	//-------------------------------------------
	//Initialize Home Banner
	//-------------------------------------------
	$('#home-slider').on('initialized.owl.carousel',function(){
		$('#home-slider .owl-prev').addClass('off');
	}).owlCarousel({
		items:1,
		margin:0,
		loop:false,
		autoplay:false,
		autoplayTimeout : 5500,
		autoplaySpeed:700,
		navText:[
			'<span class="icon-icons_down-arrow-white"></span>',
			'<span class="icon-icons_down-arrow-white"></span>'
		],
		responsive:{
			0:{
				nav:false,
				dots:true
			},
			1024:{
				nav:true,
				dots:false
			}
		}
	}).on('changed.owl.carousel',function(e){
		var count = e.item.count;
		var last = count-1;
		if(e.item.index === 0) {
			$('#home-slider .owl-next').removeClass('off');
			$('#home-slider .owl-prev').addClass('off');
		} else if(e.item.index === last) {
			$('#home-slider .owl-prev').removeClass('off');
			$('#home-slider .owl-next').addClass('off');
		} else {
			$('#home-slider .owl-prev').removeClass('off');
			$('#home-slider .owl-next').removeClass('off');
		}
	});
	
	
	
	//-------------------------------------------
	//Initialize Section Rotating Banner
	//-------------------------------------------
	$('.section-banner-slider').on('initialized.owl.carousel',function(){
		$('.section-banner-slider .owl-prev').addClass('off');
		parent = $(this).parent('.section-banner');
		$(this).find('.owl-next').prependTo(parent);
		$(this).find('.owl-prev').prependTo(parent);
		$(this).find('.owl-nav').remove();
	}).owlCarousel({
		items:1,
		margin:0,
		loop:false,
		autoplay:false,
		autoplayTimeout : 5500,
		autoplaySpeed:700,
		navText:[
			'<span class="icon-icons_down-arrow-white"></span>',
			'<span class="icon-icons_down-arrow-white"></span>'
		],
		responsive:{
			0:{
				nav:false,
				dots:true
			},
			992:{
				nav:true,
				dots:true
			}
		}
	}).on('changed.owl.carousel',function(e){
		var count = e.item.count;
		var last = count-1;
		if(e.item.index === 0) {
			$('.section-banner .owl-next').removeClass('off');
			$('.section-banner .owl-prev').addClass('off');
		} else if(e.item.index === last) {
			$('.section-banner .owl-prev').removeClass('off');
			$('.section-banner .owl-next').addClass('off');
		} else {
			$('.section-banner .owl-prev').removeClass('off');
			$('.section-banner .owl-next').removeClass('off');
		}
	});
	
	
	
	
	//-------------------------------------------
	//Initialize Tweet Slider on Home page
	//-------------------------------------------
	$('#twitter-slider').on('initialized.owl.carousel',function(){
		$('#twitter-slider .owl-prev').addClass('off');
	}).owlCarousel({
		items:1,
		margin:0,
		loop:false,
		autoplay : false,
		navText:[
			'<span class="icon-icons_down-arrow-white"></span>',
			'<span class="icon-icons_down-arrow-white"></span>'
		],
		responsive:{
			0:{
				nav:false,
				dots:true
			},
			992:{
				nav:true,
				dots:false
			}
		}
	}).on('changed.owl.carousel',function(e){
		var count = e.item.count;
		var last = count-1;
		if(e.item.index === 0) {
			$('#twitter-slider .owl-next').removeClass('off');
			$('#twitter-slider .owl-prev').addClass('off');
		} else if(e.item.index === last) {
			$('#twitter-slider .owl-prev').removeClass('off');
			$('#twitter-slider .owl-next').addClass('off');
		} else {
			$('#twitter-slider .owl-prev').removeClass('off');
			$('#twitter-slider .owl-next').removeClass('off');
		}
	});
	

	//-------------------------------------------
	//Customize variations dropdown
	//-------------------------------------------
	$('select').chosen({disable_search_threshold: 100});	
	
	
	//-------------------------------------------
	//Handle language switcher
	//-------------------------------------------
	$('#language-holder .lng-btn').click(function(){
		$(this).toggleClass('opened');
		$('#language-holder .languages').fadeToggle();
	});
	
	//-------------------------------------------
	//Handle top menu burger button
	//-------------------------------------------
	$('header .header-top .top-part .responsive-holder .burger-btn').click(function(){
		$(this).toggleClass('opened');
		$('#responsive-top-menu').slideToggle();
	});
	
	
	
	//-----------------------------------------------
	//Handle core navigation responsive arrow button
	//-----------------------------------------------
	$('#responsive-core-navigation').click(function(){
		$('#responsive-core-menu').slideToggle();
	});
	
	
	//-----------------------------------------------
	//Create responsive category dropdown by clone 
	//-----------------------------------------------
	if ($('.sidebar-filter-box').length) {
		$('.sidebar-filter-box').clone().prependTo('.main').attr('class','responsive-filter-box');
		$('.responsive-filter-box').addClass('hidden-md');
		$('.responsive-filter-box').addClass('hidden-lg');
		$('.responsive-filter-box .filter-header').append('<div class="toggle"><div class="icon-bar one"></div><div class="icon-bar two"></div></div>');
		
		$('.responsive-filter-box .filter-header').click(function(){
			$(this).toggleClass('opened');
			$('.responsive-filter-box .filter-header .toggle').toggleClass('opened');
			$('.responsive-filter-box ul').slideToggle();
		});
		
	}
	
	
	
		
	//----------------------------------------------------
	//Share articles in popup window
	//----------------------------------------------------
	$('a.share').click(function(e){
		e.preventDefault();
		
		if ($(this).prop('href').indexOf('mailto') !== 0) {
			
			var NWin = window.open($(this).prop('href'), '', 'height=600,width=600');
			if (window.focus)
			{
				NWin.focus();
	     	}
		 	return false;
		} else {
			window.location.href=$(this).attr('href');
		}
	});
	
		
	//-------------------------------------------
	//Set slider nav buttons position
	//-------------------------------------------
	setNavButtons();
	
	
	
	$('a[href*="#goog"]').click(function(e){
		location.reload();
	});
	
	
});


//-------------------------------------------
// Refresh woocommerce variation dropdowns
//-------------------------------------------
/*
function setChosen() {
	$('#booking-options').val('').trigger('chosen:updated');
}
*/


$(window).load(function(){
	//----------------------------------------------------
	//Hide input field validation errors on click or focus
	//----------------------------------------------------
	$('.wpcf7-not-valid,span.wpcf7-not-valid-tip').click(function(){
		$(this).parent('.wpcf7-form-control-wrap').children('span.wpcf7-not-valid-tip').fadeOut();
	});
	
});




$(window).resize(function(){
	//-------------------------------------------
	//Set slider nav buttons position on resize
	//-------------------------------------------
	setNavButtons();
});
